<template>
  <div class="v3-academic-consolidate-student-course">
    <ui-loading v-if="isLoading"></ui-loading>

    <div
      v-if="courseData"
      v-show="!isLoading"
      class="indicator-list"
      style="max-width: 600px"
    >
      <div
        v-for="indicator in courseData.indicators"
        :key="indicator.id"
      >
        <ui-drawer drawer-group="logros">
          <template #trigger>
            <ui-item
              class="ui-clickable"
              icon="mdi:file-outline"
              :text="indicator.name"
              :secondary="indicator.category"
            ></ui-item>
          </template>
          <template #contents>
            <div
              class="indicator-evaluators"
              style="margin-left: 50px; margin-bottom: 50px"
            >
              <div
                v-for="evaluator in indicator.evaluators"
                :key="evaluator.id"
              >
                <ui-drawer drawer-group="evaluadores">
                  <template #trigger>
                    <ui-item
                      class="ui-clickable"
                      icon="mdi:pencil-box-multiple-outline"
                      :text="evaluator.name"
                      :secondary="evaluator.grade"
                    ></ui-item>
                  </template>
                  <template #contents>
                    <ui-item
                      class="ui-clickable"
                      style="margin-left: 20px"
                      v-for="evaluation in evaluator.evaluations"
                      :key="evaluation.id"
                      icon="mdi:pencil-box"
                      :text="evaluation.name"
                      :secondary="evaluation.grade"
                    ></ui-item>
                  </template>
                </ui-drawer>
              </div>
            </div>
          </template>
        </ui-drawer>
      </div>
    </div>
    <ui-empty v-else-if="!isLoading">No hay nada aquí</ui-empty>
  </div>
</template>

<script>
/*
Uso del componente

<v3-academic-consolidate-student-course
  person-id=""
  course-id=""
  period-id=""
></v3-academic-consolidate-student-course>
*/
import useApi from '@/modules/api/mixins/useApi.js';
import apiAcademicConsolidate from '@/modules/v3/api/academic/consolidate.js';
import { UiLoading, UiDrawer, UiItem, UiEmpty } from '@/modules/ui/components';

export default {
  name: 'v3-academic-consolidate-student-course',
  mixins: [useApi],
  api: apiAcademicConsolidate,

  components: { UiLoading, UiDrawer, UiItem, UiEmpty },

  props: {
    personId: {
      type: String,
      required: true
    },

    courseId: {
      type: String,
      required: true
    },

    periodId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      courseData: null,
      isLoading: false
    };
  },

  watch: {
    personId() {
      this.fetchCourseData();
    },

    courseId() {
      this.fetchCourseData();
    },

    periodId() {
      this.fetchCourseData();
    }
  },

  mounted() {
    this.fetchCourseData();
  },

  methods: {
    async blabla() {
      alert('Aqui viene blabla');
      await this.$api.getCosaDePrueba();
      alert('Blabla terminó');
    },

    async fetchCourseData() {
      this.isLoading = true;

      this.courseData = await this.$api.getAcademicConsolidateStudentCourse(
        this.personId,
        this.courseId,
        this.periodId
      );

      this.isLoading = false;
    }
  }
};
</script>