var localGroupsCache = {};
var groupDetailsCache = {};
var teacherNodesCache = {};

export default httpClient => ({

  /* Retorna todos los nodos (Course_Section y Academic_Course_Group) en los que la persona es profesor */
  async getTeacherNodes(personId, yearId) {
    let params = {};
    if (yearId) {
      params.year = yearId
    }

    let cacheKey = personId + ':' + yearId;
    if (typeof teacherNodesCache[cacheKey] == "undefined") {
      let nodes = await httpClient.get(`/v3/people/${personId}/relevance/groups`, params);
      if (!nodes) {
        teacherNodesCache[cacheKey] = [];
      } else {
        teacherNodesCache[cacheKey] = nodes.filter(node => node.type == 'section' || node.type == 'group');
      }
    }

    return teacherNodesCache[cacheKey];
  },

  getAcademicGroups(yearId, query) {
    if (typeof query == 'undefined') {
      query = {};
    }

    let q = query.q || '-';
    let cacheKey = yearId + ':' + q;

    if (typeof localGroupsCache[cacheKey] == "undefined") {
      localGroupsCache[cacheKey] = httpClient.get(`/v3/years/${yearId}/academic/course/groups`, query);
    }

    return localGroupsCache[cacheKey];
  },

  getAcademicGroupsByPeriod(yearId, periodId, query) {
      return httpClient.get(`/v3/years/${yearId}/academic/course/groups/period/${periodId}/schedule`, query);
  },

  getAcademicGroup(groupId) {
    if (typeof groupDetailsCache[groupId] == "undefined") {
      groupDetailsCache[groupId] = httpClient.get(`/v3/academic/groups/${groupId}`);
    }

    return groupDetailsCache[groupId];
  },

  getGroupSessions(groupId) {
    return httpClient.get(`/v3/academic/course/group/${groupId}/sessions`);
  },

  getAcademicPeriodCategories(yearId, query) {
    return httpClient.get(`/v3/years/${yearId}/academic/periods`, query);
  },

  getAcademicPeriodCategoriesSchedule(yearId, query) {
    return httpClient.get(`/v3/years/${yearId}/academic/periods/schedule`, query);
  }

})