<template>
  <div class="communication-post-list">
    <section
      class="post-nonevented"
      v-if="nonevented.length"
    >
      <ui-item
        v-for="post in nonevented"
        :key="post.id"
        class="post-item ui-clickable"
        :class="{'--unread': post.isUnread}"
        @click-icon="$emit('click-post', post)"
        @click-body="$emit('click-post', post)"
        v-bind="post._item"
      >
        <template #right>
          <slot
            name="right"
            :post="post"
          ></slot>
        </template>
      </ui-item>
    </section>

    <div class="posts-events">
      <section
        class="post-day"
        v-for="day in days"
        :key="day.key"
      >
        <label>{{ day.label }}</label>
        <ui-item
          v-for="post in day.posts"
          :key="post.id"
          class="post-item ui-clickable"
          :class="{'--unread': post.isUnread}"
          @click-icon="$emit('click-post', post)"
          @click-body="$emit('click-post', post)"
          v-bind="post._item"
        >
          <template #right>
            <slot
              name="right"
              :post="post"
            ></slot>
          </template>
        </ui-item>
      </section>
    </div>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'communication-post-list',
  mixins: [useI18n],
  components: { UiItem },

  props: {
    posts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    sanitizedPosts() {
      return this.posts.map((post) => {
        post.isDraft = !post.datePublished;
        post.isPending = post.datePublished > new Date().getTime() / 1000;
        post.isUnread = post.stub && !post.stub.dateRead;

        post._item = {
          text: post.subject,

          secondary: post.isDraft
            ? post.isPending
              ? 'Visible a partir de ' + this.$ts(post.datePublished)
              : ''
            : post.event && (!post.event.isAllDay || post.event.isAllDay == '0')
            ? this.$time(post.event.dateStart, post.event.dateEnd)
            : '',

          icon: post.type.icon || 'mdi:post-outline',

          iconColor:
            post.card && post.card.color
              ? post.card.color
              : post.type.color || 'var(--ui-color-primary)',

          badge: post.isDraft || post.isPending ? ' ' : null,

          badgeColor: post.isPending
            ? 'var(--ui-color-warning)'
            : 'var(--ui-color-danger)',
        };

        return post;
      });
    },

    nonevented() {
      return this.sanitizedPosts.filter((p) => !p.event || !p.event.dateStart);
    },

    withevents() {
      return this.sanitizedPosts
        .filter((p) => p.event && p.event.dateStart)
        .sort((a, b) => b.event.dateStart - a.event.dateStart);
    },

    days() {
      let slots = [];
      let currentSlot = null;

      this.withevents.forEach((post) => {
        let date = new Date(post.event.dateStart * 1000);
        let key =
          date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();

        if (!currentSlot || currentSlot.key != key) {
          currentSlot = {
            key,
            label: date.toLocaleString(this.$i18n.language, {
              month: 'long',
              day: 'numeric',
            }),
            posts: [],
          };
          slots.unshift(currentSlot);
        }

        currentSlot.posts.unshift(post);
      });

      return slots;
    },
  },
};
</script>

<style lang="scss">
.communication-post-list {
  section {
    label {
      font-size: 12px;
      font-weight: bold;
      color: #999;
      display: block;
      padding: var(--ui-padding);
    }
  }

  .post-item.--unread {
    .text-primary {
      font-weight: bold !important;
    }
  }
}
</style>